import React, { FC, useState } from 'react';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import * as s from './style';
import { Grid, CircularProgress, Typography, InputAdornment, IconButton } from '@material-ui/core';
import BackDrop from '../BackDrop';
// import { NEW_ACCOUNT_FORM_LINK } from '../../pages/husain-scheme/new-account';
// import { LOGIN_FORM_LINK } from '../../pages/husain-scheme/login';
// import { MenuItem, Typography, Grid, TextField as MiTextField, InputAdornment, , Hidden } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Button } from '..';
import * as yup from 'yup';
import { Visibility, VisibilityOff } from '@material-ui/icons';
// import { Link } from 'gatsby';
import SigningTroubleMsgForm from './SigningTroubleMsgForm';
import { qardanFileNumberRegex } from '../../helper';


const formSchema = yup.object({
    applicantId: yup.string().matches(qardanFileNumberRegex, { message: "Invalid format" }).required("Required"),
    password: yup.string().required('Required')
})


export interface FormFields extends yup.InferType<typeof formSchema> { }

export interface Props {
    handleSubmit: (values: FormFields) => Promise<void>
}

const formInitialValues: FormFields = {
    applicantId: "",
    password: ""
}

const QardanPortalLoginForm: FC<Props> = ({ handleSubmit }) => {
    const [showPassword, setShowPassword] = useState(false);

    const onSubmit = async (values: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
        await handleSubmit(values);
        setSubmitting(false);
    }

    return (
        <Formik initialValues={formInitialValues}
            validationSchema={formSchema}
            onSubmit={onSubmit}
        >
            {({ isValid, isSubmitting }) => (
                <Grid style={{ ...s.formStyle(), maxWidth: '500px', margin: '0 auto', padding: "0", backgroundColor: "transparent" }} >
                    <Form style={{ ...styleA, borderRadius: "5px 5px 0px 0px" }} >
                        <BackDrop open={isSubmitting} >
                            <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
                        </BackDrop>
                        {/* /////////////////////////////////////////   login credential fields /////////////////////////////////////// */}
                        <Grid container item spacing={1} xs={12} direction='column' justify='center' >
                            <Grid item container alignItems='center' >
                                <Field required fullWidth component={TextField} name='applicantId' label="Enter File Number" type='text' variant='outlined' />
                            </Grid>
                            <br />
                            <Grid item container alignItems='center' >
                                <Field
                                    required component={TextField} fullWidth name='password' label='Password' variant='outlined'
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { setShowPassword(!showPassword) }}
                                                onMouseDown={() => { setShowPassword(!showPassword) }}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />
                            </Grid>
                            <br />
                            <Grid item container direction='column' >
                                <Button disabled={(!isValid || isSubmitting)} size='large' color='primary' type='submit' >
                                    {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>Login</div>}
                                </Button>
                                <br />
                                <Typography >Having trouble signing in? <SigningTroubleMsgForm portalType='Qardan' /></Typography>
                            </Grid>
                            {/* <br /><hr /><br /> */}
                            {/* <Typography >Don't have an account? <Link to={'a'} >Click here</Link></Typography> */}
                        </Grid>
                    </Form>
                </Grid>
            )}
        </Formik>
    )
}

export default QardanPortalLoginForm;


const styleA = {
    minWidth: '280px', maxWidth: '500px', margin: '0 auto', padding: "20px", backgroundColor: "white"
}